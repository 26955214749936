import React from "react"
import Seo from "../../components/seo"
import { useWordPressApi } from "../../hooks/useWordPressApi"
import PageLayout from "../../layouts/PageLayout"
import { parseHtml } from "../../utils/parseHtml"

/**
 * Component used to preview a WordPress Page object
 *
 */
function PreviewPage({ location: { search } }) {
  const params = new URLSearchParams(search)
  // ID of WordPress Post object, we use this to lookup the latest revision of the post
  const pageId = params.get("preview_id")

  const { loading, data, error } = useWordPressApi(
    `/wildearth/v1/items/${pageId}/revisions`
  )

  if (error) {
    return <h1>Shoot! Something went wrong.</h1>
  }

  if (loading) {
    return <h1>Generating preview for post: {pageId}</h1>
  }

  if (!data) {
    return (
      <>
        <h1>Oops!</h1>
        <p>Looks like we couldn't generate a preview for page: {pageId}</p>
      </>
    )
  }

  return (
    <PageLayout>
      <Seo title={data.post_title} />
      {parseHtml(data.post_content)}
    </PageLayout>
  )
}

export default PreviewPage
