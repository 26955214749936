/**
 * Convert an object into an encoded URL search string
 *
 * @param {Object} obj structured URL parameters where the key is a URL param and the value is the URL param value
 * @returns string
 */
export function getParamsFromObj(obj) {
  return Object.entries(obj)
    .map(
      ([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`
    )
    .join("&")
}
