import { useEffect, useState } from "react"
import { getParamsFromObj } from "../utils/getParamsFromObj"

export function useWordPressApi(endpoint, params = {}, options = {}) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [data, setData] = useState(null)

  useEffect(() => {
    async function makeRequest(endpoint) {
      try {
        setLoading(true)

        const qs = getParamsFromObj(params)
        const url = qs.length
          ? `${process.env.GATSBY_APP_WORDPRESS_REST_API_BASE_URL}${endpoint}?${qs}`
          : `${process.env.GATSBY_APP_WORDPRESS_REST_API_BASE_URL}${endpoint}`

        const request = await fetch(url, {
          ...options,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${window.btoa(
              process.env.GATSBY_APP_WORDPRESS_AUTHOR_USERNAME +
                ":" +
                process.env.GATSBY_APP_WORDPRESS_AUTHOR_PASSWORD
            )}`,
            ...options.headers,
          },
        })

        const json = await request.json()
        // WordPress provides a standardized object response when an error occurs
        if (json?.data?.status && json.data.status !== 200) {
          throw new Error(json.message)
        }

        // Let's flatten our response into a single object if there's only one element
        const res =
          Array.isArray(json) && json.length === 1
            ? json.reduce((acc, curr) => acc.concat(curr))
            : json

        setData(res)
        setLoading(false)
      } catch (error) {
        console.error(error.message)
        setError(error)
        setLoading(false)
      }
    }

    makeRequest(endpoint)
  }, [])

  return { loading, error, data }
}
